@import "../../styles/variables.scss";

.whitecard {
  h2 {
    margin-top: 1em;
    // color:$mute;
    font-weight: 400;
  }
  .whitecard-body {
    background: white;
    border-radius: 5px;
    padding: 1em;
    margin-top: 1em;
    box-shadow: $box-shadow;
    color: $text-color;
  }
}
