@import "../styles/variables.scss";

#login-page {
  display: flex;
  // flex-wrap: wrap;
  height: auto;
  font-family: "Nunito";
  aside {
    
    height: 100%;
    box-sizing: border-box;
  }
  .hero {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fefaff;
    height: 100%;
    padding: 5em 5em;
    h1 {
      font-size: 2rem;
      margin-bottom: 0.25em;

      font-weight: 600;
      a {
        color: #402c4b !important;
        text-decoration: none;
      }
    }
    span.slogan {
      display: inline-block;
      font-size: 1.5rem;
      margin-bottom: 1.5em;
      font-weight: 200;
      color: #402c4b;
    }
    img.social-hero {
      align-self: center;
      margin-bottom: 3em;
    }
    p {
      text-align: center;
      color: #402c4b;
    }
  }
  aside.form {
    flex: 1;
    background: #fff;
    display: flex;
    padding-bottom: 2em;
    flex-direction: column;
    .form-head {
      flex: 1;
    }
    #login-form {
      display: flex;
      flex-direction: column;
      flex: 50;
      // justify-content: center;
      // height:90%;
    }

    .form-footer {
      text-align: center;
      color: #402c4b;
      flex: 1;
      font-size: 0.5rem;
    }
    h1 {
      font-size: 2rem;
      margin-bottom: 0.5em;
    }
    p {
      margin-bottom: 2em;
      a {
        color: $primary;
      }
    }
  }
}



@media (max-width: 768px) {
  #login-page {
    flex-direction: column;
    .hero {
      // background:red;
    }

    aside {
      padding: 2em;
    }
  }
}

@media only screen and (min-width:1px) {
  #login-page {
    .form {
      padding-top:.5em !important;
      padding-left:1em !important;
      padding-right:1em !important;
    }
    .hero {
      display:none;
    }
  }
}

@media only screen and (min-width: 769px) {
  #login-page {
    flex-direction: row;
    .form {
      padding-left:5em !important;
      padding-right:5em !important;
    }
    .hero {
      display:block;
    }
  }
}




