@import "../../styles/variables.scss";
$active-nav: #C19AEA;

.new-widget {
  outline:none;
  width: 100%;
  padding:1em;
  border:none;
  background:white;
  box-sizing:border-box;
  border-radius:5px;
  margin-bottom:1em;
  box-shadow:0px 5px 10px rgba(170, 145, 196, 0.300);
  font-size:1rem;
}

.btn-primary {
  text-decoration: none;
  background:#C19AEA;
  padding:.8em .5em;
  // width:100%;
  border-radius:5px;
  color:white;
  margin-bottom: 1em;
  border:none;
  box-shadow:0px 5px 10px rgba(170, 145, 196, 0.300);
  font-size:1rem;
  box-sizing:border-box; 
}

.btn-add-new-widget {
  background:#5F31E0;
  padding:.8em 0;
  width:100%;
  border-radius:12px;
  color:white;
  margin-bottom: 1em;
  border:none;
  box-shadow:0px 5px 10px rgba(170, 145, 196, 0.300);
  font-size:1rem;
  box-sizing:border-box;
}

.button {
  width:100%;
  margin-bottom: 1em;
  border:none;
  box-shadow:0px 5px 10px rgba(170, 145, 196, 0.300);
  font-size:1rem;
  box-sizing:border-box;
  background:$primary;
  border-radius:12px;
  margin-top:1em;
  padding: .8em 2em;
  font-size:18px;
  color:#fff;
  cursor:pointer;
  text-decoration: none;
  &.button-primary {
    background:$primary;
    color:#fff;  
  }
  &.button-secondary {
    background:#fff !important;
    color:$primary;
  }
  &.button-secondary2 {
    background:#fff;
    color:$primary;
    width: auto;
    color: var(--primary);
    border: 1px solid rgba(170, 145, 196, 0.2);
    box-shadow: 0px 3px 2px rgba(170, 145, 196, 0.2);
    text-decoration: none;
    &.active {
      background: var(--primary);
      color:#fff;
    }
  }
  &.button-mini {
    font-size:1rem;
    // color:#fff !important;
    padding-left:1.5em;
    padding-right:1.5em;
    font-weight:600 !important;
  }
  &.no-shadow {
    box-shadow: none;
  }
}

.list-filterdate-buttons {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // padding:0 1em;
  button {
    // align-self: flex-start;
    // min-width:500px;
  }
}