@import "../../styles/variables.scss";

@keyframes widgetListIn {
  0% {
    transform: translateY(1em);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.links-widget {
  animation: widgetListIn 0.3s ease-in-out;

  list-style: none;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 15px 25px rgba(170, 145, 196, 0.3);
  // background:transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  &.mode-manage {
    box-shadow:none;
    .context-icon {
      display:none;
    }
  }
  &.mode-edit {
    box-shadow:none;
  }
  li {
    display: flex;
    transition: all 0.3s ease-in-out;
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      a {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    &:hover {
      cursor: pointer;
      background: #f7f4fa;
    }
    a {
      display: flex;
      width: 100%;
      h1 {
        flex: 1;
        font-size: 1em !important;
        font-weight: normal;
        display: inline;
      }
      span {
        text-align: right;
      }
    }
    background: #fff;
    color: $active-nav;

    box-sizing: border-box;
    border-bottom: 1px solid $line-nav;
    font-size: 1.2em;
  } //li

  a {
    text-decoration: none;
    color: var(--primary);
    padding: 0.5em 0 0.5em 1em;
  }
  .context-icon {
    padding: 0.5em 0.5em;
    vertical-align: center;
    &:active {
      font-weight: bold;
      color: darken($active-nav, 10%);
    }
  }
}
