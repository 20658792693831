@import "../../styles/variables.scss";

$default-spacing: 1em;
.default-layout-pageV3 {

  display: flex;
  flex-direction: column;
  .headerboxV3 {
    padding: $default-spacing 0 $default-spacing + 4em 0;
    // background: #e8d4fc;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;
    border-radius: 5px;
    // text-align:center;
    #user-name {
      margin-bottom: 0.5em;
      // text-align: center;
    }

  }

  .default-content-boxV3 {
    background: white;
    border-radius: 5px;
    padding: 1em;
    margin-top: 1em;
    box-shadow: $box-shadow;
  }

  .page-title {
    font-size: 1.75rem;
  }

  .main-content {
    margin-top:-4em;
    padding-left:1em;
    padding-right:1em;
  }
}

@media (min-width: 420px) {
  .main-content {
    margin: auto;
    width: 80%;
  }
}
@media (min-width: 420px) {
  .page-title {
    width: 80%;
  }
}
