.top-barv3 {
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
  .top-bar--icon {
    // padding-left:1em;
    // margin-left:1em;
    // margin-right:1em;
  }
  .top-bar--title {
    text-align: center;
    // margin-left: auto;
  }
}

@media (min-width: 420px) {
  .top-barv3 {
    .top-bar--icon {
      width: 10%;
      text-align: left;
      padding-left: 0 !important;
    }
  }
}

@media (min-width: 420px) {
  .top-barv3 {
    margin: auto;
    width: 80%;
  }
}
