@import "../../styles/variables.scss";

$default-spacing: 1em;
.default-layout-pageV2 {
  display: flex;
  flex-direction: column;
  .headerboxV2 {
    padding: $default-spacing 0 $default-spacing + 4em 0;
    background: #e8d4fc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    // text-align:center;
    #user-name {
      margin-bottom: 0.5em;
      text-align: center;
    }

    #status {
      text-align: center;
    }
    #profile-picture {
      background-size: cover;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 0.5em;
      text-align: center;
    }
  }

  .default-content-boxV2 {
    background: white;
    border-radius: 5px;
    padding: 1em;
    margin-top: 1em;
    box-shadow: $box-shadow;
  }

  .page-title {
    font-size: 1.75rem;
  }

  .main-content {
    margin-top:-4em;
  }
}

@media (min-width: 420px) {
  .main-content {
    margin: auto;
    width: 80%;
  }
}
@media (min-width: 420px) {
  .page-title {
    width: 80%;
  }
}
