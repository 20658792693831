@import "../../styles/utility.scss";
@keyframes actionSheetIn {
  0% {
    transform: translate(-50%, 75%);
    opacity:0;
  }
  100% {
    transform: translate(-50%, -100%);
    opacity:1;
  }
}

#action-sheet {
  // animation: actionSheetIn .3s ease-in-out;
  // animation-fill-mode: forwards;
  // display:block;
  z-index: 999;
  position: absolute;
  width: 90%;
  bottom:0px;
  margin:0 auto;
  left:50%;
  transform: translateX(-50%);
  // top: 100%;
  // left: 50%;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  // transform: translate(-50%, -100%);

  // opacity:.95;
  ul {
    list-style: none;
    li {
      background: white;
      opacity: 1;
      border-bottom: 1px solid $line-nav;
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        button {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        // top:1px;
      }
      &:last-child {
        /* for cancel button */
        font-weight: bold;
        margin-top: 0.5em;

        button {
        }
      }
      button {
        color: var(--primary);
        padding: 0.75em 1em;
        font-size: 1.25rem;
        display: flex;
        width: 100%;
        border: none;
        background: white;
        i {
          flex: 1;
        }
        span {
          text-align: center;
          display: block;
          flex: 9;
        }
        &:hover {
          background: #f7f4fa;
        }
      }
    }
  }
}