@import "../../styles/variables.scss";

#tidio-chat-iframe {
    margin-bottom: 65px !important;
}

#tidio-chat-iframe.cookieaccepted {
    margin-bottom: 0px !important;
}

header.landing-header {
    display: flex;
    box-shadow: 0px 2px 5px rgba(230, 230, 230, 0.25);
    padding: 1em 0em;
    position: fixed;
    width: 100%;
    backdrop-filter: blur(20px);
    background: #ffffffd4;
    z-index: 100;

    .logo-container {
        flex: 1;
        display: flex;
        align-items: center;

        .perona-text-logo {
            color: $primary;
            font-weight: 700;
        }
    }

    .burger-container {
        display: none;
    }

    .menu-container {
        flex: 1;
        // display:flex;
        justify-content: flex-end;

        ul.menu {
            display: flex;
            flex-direction: row;
            list-style: none;
            // background:orange;
            justify-content: flex-end;

            li {
                margin-left: 2em;

                a {
                    text-decoration: none;
                    font-weight: 600;
                    color: #000;

                    // color:red;
                    &.active {
                        font-weight: 700;
                        color: $primary;
                    }

                    &.login-button {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.landing-hero {
    margin-top: 1.5em;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;

    .left {
        // background:pink;
        // width:10%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subtitle {
            border-left: 3px solid $primary;
            padding-left: 1em;
            margin-top: 1em;

            p {
                font-weight: 500;
                font-size: 18px;
            }
        }
    }

    .right {
        // flex:1;
        // background:green;
    }
}

.testimonials-container {
    margin-top: 1.5em;
    display: flex;
    justify-content: center;

    h2 {
        text-align: center;
        font-weight: 800;
        font-size: 32px;
    }

    .testimony-container {
        display: flex;
        justify-content: center;
    }

    .testimony-box {
        width: 100%;
        margin-top: 1.5em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .right {
            // flex:1;
            // padding-left:1.5em;
            margin-top: 1em;
            font-size: 1.25rem;

            p {

                text-align: center;
                font-style: italic;
                line-height: 1.5rem;
                cursor: default;
            }

            em {
                display: block;
                text-align: center;
                font-style: normal;
                font-size: 1rem;
                line-height: 1.25rem;
            }


        }
    }
}

.subtitle-name {
    font-weight: bold;
    color: $primary;
    margin-top: .5em;
    font-style: normal;
}

.subtitle-title {
    color: rgb(58, 58, 58);
    font-size: .85rem;
    font-weight: 300;
    font-style: normal;
}

.testimony-pp {
    width: 100px;
    height: 100px;
    border-radius: 50%;

}

.supercallaction-container {
    background: $primary;
    padding-top: 1.5em;
    padding-bottom: 1em;
    color: #fff;
    margin-top: 2em;

    .maxw-container {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    h2 {
        text-align: center;
        font-size: 32px;
    }
}

.landing-footer {
    padding-top: 2em;
    padding-bottom: 2em;

    .main-footer {
        display: flex;

        .motto {
            display: inline-block;
            margin-top: .85em;
        }

        .left {
            flex: 1;

            .text-logo {
                color: $primary;
            }
        }

        .right {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            .menu-footer {
                margin-left: 2em;
                font-size: 1.25rem;

                span {
                    display: inline-block;
                    font-weight: bold;
                    margin-bottom: 1em;
                }

                ul {
                    list-style: none;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }

    .footnote {
        margin-top: 2em;
        color: #646464;
    }
}

.highlight-points {
    h2 {
        margin-left: .5em;
    }
}

.highlight-point {
    box-sizing: border-box;

    .title {
        font-size: 1.25rem;

    }

    .content {
        font-size: 1rem;
    }

}

@media only screen and (max-width: 768px) {
    .landing-header {

        .landing-header-container {
            flex-direction: column;
            align-items: flex-start;

            .logo-container {
                width: 100%;
                justify-content: space-between;
            }
        }

        .burger-container {
            display: block !important;
        }

        .menu-container {
            display: none;
            // width:100%;
            // justify-content:center;
            // .menu {
            //     width:100% !important;
            //     flex-direction:column !important;
            //     align-items:center !important;
            //     li {
            //         margin-left:0 !important;
            //     }
            //     .login-button {
            //         margin-top:1em;
            //         display:inline-block;
            //     }
            // }
        }
    }

    .landing-hero {
        flex-direction: column-reverse;

        .tagline {
            margin-top: 1em;
            text-align: center;
            color: $primary;
        }
    }

    .hero-image-container {
        display: flex;

        img {
            margin: auto
        }
    }

    .highlight-points {
        flex-direction: column;
        justify-content: center !important;

        .right.react-reveal {
            width: 100% !important;
            // display:flex;
            // justify-content: center;
            // img {
            //     margin:auto;
            // }
        }

        .highlight-logo-continer {
            display: flex;

            img {
                margin: auto; //center logo on mobile
            }
        }
    }

    .highlight-point {
        min-width: 100% !important;
    }

    .testo {
        // display: none !important;
        // background-color: red;
        flex-direction: column !important;

        .left {
            display: flex;
            justify-content: center;
        }

        p {
            text-align: center;
            margin-top: 1em;
        }

        em {
            text-align: center;
        }
    }

    .landing-footer {
        display: none;

        .main-footer {
            flex-direction: column;

            .left {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .right {
                justify-content: center;
                margin-top: 1em;
            }
        }

        .footnote p {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1200px) {

    .highlight-points {
        // flex-direction: column;
    }

}

@media only screen and (max-width: 412px) {
    .plan {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.menu-container-show {
    display: flex !important;
    width: 100%;
    justify-content: center;

    .menu {
        width: 100% !important;
        flex-direction: column !important;
        align-items: center !important;

        li {
            margin-left: 0 !important;
            // font-weight: bold;
        }

        .login-button {
            margin-top: 1em;
            display: inline-block;
            color: #fff;
        }
    }
}

.common-page {

    h1 {
        .accent {
            color: $primary;
        }
    }

    .somewords {
        text-align: center;
        font-weight: 500;
        color: #A08DB4;
        display: inline-block;
        // background: black;
        margin: 0 auto;
        margin-top: 1em;
    }
}

.plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));

    .plan {
        margin-bottom: 2em;

        &.primary {
            background: $primary;
            color: #fff;
        }

        color:#000;
        background: #fff;
        margin-left:12px;
        margin-right: 12px;
        display: flex;
        border-radius: 21px;
        padding: 1.5em;
        flex-direction: column;
        max-width: 344px;
        box-sizing: border-box;

        .head {
            display: flex;
            align-items: center;

            h3 {
                font-size: 36px;
                font-weight: 600;
                // color: #fff;
            }

            .badge {
                font-size: 12px;
                display: inline-block;
                padding: 9px;
                background: #fff;
                font-weight: 700;
                color: $primary;
                border-radius: 9px;
                margin-left: auto;
            }
        }

        .price-container {
            display: flex;
            // color: #fff;
            align-items: center;

            .price {
                font-size: 64px;
                font-weight: 800;
            }

            .per {
                margin-left: .5em;
                font-size: 24px;
            }
        }

        .description {
            // color: #fff;
            // font-weight: 500;
            font-size: 20px;
        }

        .features {
            list-style: none;

            li {
                // color: #fff;
                font-size: 20px;
                margin-top: .5em;

                .icon {
                    color: rgba(0, 0, 0, 0.25);

                    &.primary {
                        color: rgba(255, 255, 255, 0.4);
                    }

                    margin-right: 6px;
                }
            }
        }
    }

}

.primary-text {
    color: $primary
}