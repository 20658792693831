@keyframes overlayIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

#overlay {
  // background:red;
  // opacity:.8;
  animation: overlayIn .25s ease-in-out;
  // display: block;
  position: fixed;
  width: 100%;
  height: 100vh;
  left:0;
  bottom:0;
  z-index:9999;
  &:after {
    width: 100%;
    height: 100%;
    background: black;

    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.8;
    content: "";
    z-index: 0;
  }
}

