$primary-background: #FEFAFF;
$active-nav: #c19aea;
$line-nav: #E8E0FF;
$default-spacing: 1.5em;
$box-shadow: 0px 5px 10px rgba(170, 145, 196, 0.15);
$text-color: #262626;
$primary-text: darken($active-nav,5%);
$warning : #ffde38;
$mute : rgb(44, 44, 44);
$primary: #5F31E0;
:export {
  activeNav : $active-nav
}