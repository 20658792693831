@import "./styles/variables.scss";
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700,800&display=swap");

*,
body,
html {
  padding: 0;
  margin: 0;
  font-family: "Nunito", "Helvetica Neue", sans-serif;
}
body,
html {
  height: 100%;
  //   ::-webkit-scrollbar {
  //     width: 0px;  /* Remove scrollbar space */
  //     background: transparent;  /* Optional: just make scrollbar invisible */
  // }
}
body {
  background: $primary-background;
}

#root {
  height: 100%;
  --primary:#5F31E0;
  --darkgrey:#3e3e3e;
  --success:#66D8C6;
}

#dashboard-page {
  display: grid;
  grid-template-areas:
    "hb hb hb hb hb hb hb hb hb"
    ". . wm wm wm wm wm . .";
}

.App {
  height: 100%;
}

// .App {
//   display: grid;
//   grid-template-areas:
//     "hb hb hb hb hb hb hb hb hb"
//     ". . wm wm wm wm wm . .";
//   // grid-row-gap:$default-spacing;
// }

nav.top-nav {
  grid-area: tn;
  margin-top: $default-spacing;
  ul {
    list-style: none;
    display: flex;

    li {
      background: white;
      flex: 1;
      &.active {
        background: $active-nav;
        a {
          color: white;
        }
      } //active

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      a {
        color: $active-nav;
        text-decoration: none;
        text-align: center;
        width: 100%;
        display: inline-block;
        padding: 0.5em 0.5em;
        box-sizing: border-box;
        font-size: 0.9rem;
      }
    } //li
  } //ul
}

main.widget-manager {
  grid-area: wm;
  // background:transparent !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  // margin-top: -2em;
  margin-bottom: 1em;
  // background:red;
}

#dashboard-page {
  width: 100%;
}

.perona-logo {
  font-weight: bold;
  text-decoration: none;
  color: $primary;
}

.perona-logo-home {
  font-size: 1.25rem;
}

.screen-container {
  padding:5em 1em 4em 1em; 
  // background:red
}