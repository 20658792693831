@import "../../styles/variables.scss";
.btn {
  text-decoration: none;
  border-radius: 5px;
  padding: 0.5em;
  box-sizing: border-box;
  background-color: white;
  color: darken($primary, 3%);
  border: 1px solid $line-nav;
  border-bottom:1px solid $line-nav; 
  // transition: all 0.3s ease-in-out;
  &:hover {
    background: darken($primary, 3%);
    border: 1px solid darken($primary, 3%);
    color: white;
  }
  &.btn-light {
    &:hover {
      background: $primary-background;
      border: 1px solid $line-nav;
      color: darken($primary, 5%);
    }
  }

  &.btn-warning {
    &:hover {
      background: $warning;
      border: 1px solid $warning;
      color: darken($warning, 25%);
    }
  }
  &.btn-sm {
    padding: 0.25em 0.5em;
  }
}
