@import "./variables.scss";

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.form {
  label {
    color: $text-color;
  }
  input {
    margin-top: 1em;
  }
}

.m0-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left:auto !important;
}

.text-center {
  text-align: center;
}

.color-white {
  color: white;
}

.mb-3 {
  margin-bottom: 100px !important;
}
.mt-1 {
  margin-top: .5em;
}

.mt-2 {
  margin-too:1em;
}
.mt-3 {
  margin-top: 100px !important;
}

.maxw-container {
  max-width:1200px;
  width:100%;
  // background:red;
  display:flex;
  margin:auto;
  padding:0em 1.5em;
  box-sizing:border-box;
}

.align-items-center {
  align-items:center;
  // background:green;
}